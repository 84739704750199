/* Services.css */
.services {
  text-align: center;
  padding: 50px 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 90%;
  margin: 0 auto;
}

h1 {
  font-size: 2.0rem;
  margin-bottom: 50px;
}

h1 .highlight {
  font-weight: bold;
  color: #333; /* Adjust color as necessary */
}

.columns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.column {
  flex: 1;
  margin: 0 10px;
  max-width: 35%;
  text-align: center;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

p {
  font-size: 1rem;
  line-height: 1.7;
  margin-bottom: 20px;
}

a.link {
  color: #e74c3c; /* Change color to match the link styles */
  text-decoration: none;
}

a.link:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .columns {
    flex-direction: column;
    align-items: center;
  }

  .column {
    max-width: 100%;
    margin: 20px 0;
  }
}
