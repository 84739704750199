/* Reset styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  line-height: 1.6;
  overflow-x: hidden;
}

/* Container */
.container {
  width: 80%;
  margin: 0 auto;
  padding: 20px 0;
}

/* Header */
.header {
  background: #ffffff;
  color: #000000;
  padding: 10px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.header .container {
  display: flex;
  background: url('./assets/img/LangwoodTutoring-Logo.jpg') no-repeat center center/cover;
  background-size: contain;
  justify-content: space-between;
  align-items: center;
}

.header ul {
  list-style: none;
}

.header ul li {
  display: inline;
  margin-left: 20px;
}

.header ul li a {
  color: #000000;
  font-size: large;
  text-decoration: none;
}

/* Common Section Styling */
section {
  text-align: center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
}

section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

section p {
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 20px;
}

section .cta-btn {
  background: #989899;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
  display: inline-block;
}

section .cta-btn:hover {
  background: #474849;
  transition: background-color 0.3s ease;
}

/* Home Section */
.home {
  /* background: url('./assets/img/AdobeStock_193307850_Preview.jpeg') no-repeat center center/cover; */
  background-color: #ffffff;
  background-size: cover;
  color: #000000;
}

.home h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.home p {
  font-size: 1.5rem;
  max-width: 700px;
  margin: 0 auto;
}

/* About, Classes, Contact Sections */
.about, .classes, .contact {
  background-size: cover;
  color: #000000;
  text-align: center;
}

.classes p, .contact p {
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 20px;
}

.classes h2, .contact h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

/* Footer */
footer {
  background: #333;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  margin-top: 20px;
}

/* Scroll Behavior */
html {
  scroll-behavior: smooth;
}

ul {
  list-style-type: none; /* Removes the default bullet points */
  padding-left: 0; /* Removes any default padding on the left side */
  margin-left: 0; /* Removes left margin */
}

ul li {
  margin-bottom: 10px; /* Adds some spacing between list items for readability */
}

ul li::before {
  content: "•"; /* Adds a custom bullet point (could use a different symbol if desired) */
  color: #ffffff; /* Color for the bullet */
  font-weight: bold; /* Makes the bullet more pronounced */
  display: inline-block; /* Ensures the bullet appears before the text */
  width: 1em; /* Adds some space between the bullet and the text */
  margin-right: 10px;
}

li strong {
  font-weight: bold; /* Keeps the bold emphasis for the strong text */
}


/* Responsive Media Queries */
@media (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0px 5px 0px 5px;
  }

  .header {
    background: #ffffff;
    color: #000000;
    padding: 4px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
  }

  .header .container {
    display: flex;
    background: url('./assets/img/LangwoodTutoring-Logo.jpg') no-repeat center center/cover;
    background-size: 80%;
    justify-content: space-between;
    align-items: center;
  }

  .header ul {
    text-align: right;
  }

  .header ul li {
    display: block;
    margin: 0px 0;
  }

  .home h2 {
    font-size: 2.5rem;
  }

  .home p {
    font-size: 1.2rem;
    padding: 0 20px;
  }

  section h2 {
    font-size: 2rem;
  }

  section p {
    font-size: 1rem;
    padding: 0 20px;
  }
}

@media (max-width: 480px) {
  .home h2 {
    font-size: 2rem;
  }

  .home p {
    font-size: 1rem;
  }

  .cta-btn {
    padding: 8px 16px;
  }
}
